interface SettingsFile {
  api_url: string;
  api_scope: string;
  client_id: string;
  client_authority: string;
  redirect_url: string | undefined;
  sub_key: string;
}

class ServiceSettings {
  private _apiURL: string;
  private _apiScope: string;
  private _clientId: string;
  private _authority: string;
  private _redirectURL: string | undefined;
  private _subscriptionKey: string;

  public get apiURL(): string {
    return this._apiURL;
  }

  public get apiScope(): string {
    return this._apiScope;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public get subscriptionKey(): string {
    return this._subscriptionKey;
  }

  public get authority(): string {
    return this._authority;
  }

  public get redirectURL(): string | undefined {
    return this._redirectURL;
  }

  constructor() {
    const data = (window as any).SETTINGS as SettingsFile;

    this._apiURL = data.api_url;
    this._apiScope = data.api_scope;
    this._clientId = data.client_id;
    this._authority = data.client_authority;
    this._redirectURL = data.redirect_url;
    this._subscriptionKey = data.sub_key;
  }
}

export default new ServiceSettings();
