import React, {Component, ReactElement} from "react";

export interface HeadProps {
  title: string;
  displayElement?: boolean;
}

export default class Head extends Component<HeadProps> {
  componentDidMount(): void {
    document.title = "Global Device Card - " + this.props.title;
  }

  render(): ReactElement {
    const {displayElement, title} = this.props;
    if (displayElement) {
      return (
        <div className="header">
          <h1>{title}</h1>
        </div>
      );
    }

    return <React.Fragment></React.Fragment>;
  }
}
