import getTheme from "./components/theme";
import Layout from "./components/app/layout";
import React from "react";
import {AppContext, AppContextView} from "./components/app/app-context";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import "./styles/global.scss";

function App() {
  return (
    <ThemeProvider theme={getTheme()}>
      <CssBaseline />
      <AppContextView>
        <AppContext.Consumer>
          {(context) => <Layout user={context.user} />}
        </AppContext.Consumer>
      </AppContextView>
    </ThemeProvider>
  );
}

export default App;
