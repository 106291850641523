import React, {Component, ReactElement} from "react";

export default class AppTitle extends Component {
  render(): ReactElement {
    return (
      <div id="main-title">
        <h2>Global Device Card Portal</h2>
        <ul className="logos">
          <li>
            <img
              alt="oticon-logo-neg"
              src="/img/oticon_no_payoff_white.svg"
              width={144}
              height={37}
            />
          </li>
          <li>
            <img
              alt="sonic-logo-neg"
              src="/img/sonic-logo-neg.svg"
              width={121}
              height={45}
            />
          </li>
          <li>
            <img
              alt="bernafon-logo-neg"
              src="/img/bernafon-logo-neg.svg"
              width={119}
              height={36}
            />
          </li>
          <li>
            <img
              alt="philips-logo-neg"
              src="/img/philips-logo-neg.svg"
              width={52}
              height={66}
            />
          </li>
        </ul>
      </div>
    );
  }
}
