import Loader from "../../common/loader";
import NoResults from "./no-result";
import React, {ChangeEvent, Component, ReactElement} from "react";
import {debounce} from "lodash";
import {InputBase} from "@material-ui/core";
import {IServices} from "../../../service/services";
import {DeviceInfo} from "../../../service/domain/devices";
import {ApplicationError} from "../../../common/errors";
import ErrorPanel from "../../common/error";
import SerialNumberOverview from "./overview";

export interface SearchProps {
  initialValue?: string;
  services: IServices;
}

export interface SearchState {
  search: string;
  actualSerialNumber: string;
  loading: boolean;
  info: DeviceInfo | null;
  notFound: boolean;
  error: ApplicationError | null;
}

export default class Search extends Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      actualSerialNumber: "",
      search: props.initialValue || "",
      loading: false,
      info: null,
      notFound: false,
      error: null,
    };
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    if (this.state.loading) {
      // this is done instead of disabling the input field
      // to maintain the focus
      return;
    }
    // Note: remove spaces from the serial number value
    this.setState({
      search: e.target.value.replace(/\s/g, ""),
      actualSerialNumber: "",
      notFound: false,
    });

    if (e.target.value === "") {
      this.setState({
        error: null,
        notFound: false,
        info: null,
      });
      return;
    }

    this.onValueChange();
  }

  onUpdate() {
    this.search();
  }

  onValueChange = debounce(
    () => {
      this.search();
    },
    500,
    {leading: false, trailing: true}
  );

  search(): void {
    const {actualSerialNumber, search, loading} = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
      error: null,
      info: null,
      notFound: false,
    });

    const {services} = this.props;

    let searchCriteria = search;
    if (actualSerialNumber !== "" && actualSerialNumber !== search) {
      // The API returns device information by device serial number or by
      // battery serial number.
      // Since the user can update the battery serial number and can find a
      // device by battery serial number, we need to make sure that
      // we use the device serial number to look for an instrument after its
      // battery SN is updated.
      searchCriteria = actualSerialNumber;

      this.setState({
        search: actualSerialNumber,
      });
    }

    services.devices.getDeviceBySerialNumber(searchCriteria).then(
      (data) => {
        let notFound = data === null || data.status === "notFound";

        // API can return only serial number with Firmware Version
        // which is currently useless information for GDC UI.
        // This should be changed when UI will support Firmware Versions
        if (data?.value?.manufacturerName === null) {
          notFound = true;
          data = null;
        }

        const actualSerialNumber =
          data?.status === "success" ? data.value?.serialNumber : search;
        this.setState({
          info: data,
          notFound: notFound,
          loading: false,
          actualSerialNumber: actualSerialNumber || "",
        });
      },
      (error: ApplicationError) => {
        this.setState({
          loading: false,
          error,
          actualSerialNumber: "",
        });
      }
    );
  }

  render(): ReactElement {
    const {error, info, loading, search, notFound} = this.state;
    return (
      <div>
        <div id="search-area" className={loading ? "ui-loading" : ""}>
          {loading && <Loader className="covering" />}
          <label htmlFor="search-field">Search for a serial number</label>
          <InputBase
            id="search-field"
            name="value"
            value={search}
            onChange={this.onChange.bind(this)}
            autoFocus={true}
            placeholder="Search on Hearing Aid & battery serial number"
            fullWidth
          />
        </div>
        <div id="results-area">
          {error && <ErrorPanel error={error} />}
          {notFound && <NoResults search={search} />}
          {info !== null && info.value !== null && (
            <SerialNumberOverview
              data={info.value}
              services={this.props.services}
              onUpdate={() => this.onUpdate()}
            />
          )}
        </div>
      </div>
    );
  }
}
