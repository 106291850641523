export interface UserOptions {
  name: string;
  oid: string;
  iss: string;
  preferred_username: string;
  exp: number;
  roles: string[];
  given_name?: string;
  emails?: string[];
  extension_user_country?: string;
  extension_user_region?: string;
  tfp?: string;
}

function getRoles(options: UserOptions | undefined): string[] {
  if (options === undefined) {
    return [];
  }
  return options.roles || [];
}

export class User {
  private _options: UserOptions | null;
  private _roles: string[];
  private _superUser: boolean;

  constructor(options?: UserOptions) {
    if (options) {
      const roles = getRoles(options);
      this._options = options;
      this._roles = roles;
      this._superUser = roles.includes("ADMIN");
    } else {
      this._options = null;
      this._roles = [];
      this._superUser = false;
    }
  }

  get name(): string {
    return this._options?.name || "";
  }

  get issuer(): string {
    return this._options?.iss || "";
  }

  get email(): string {
    return this.options?.preferred_username || "";
  }

  get options(): UserOptions | null {
    return this._options;
  }

  get roles(): string[] {
    return this._roles;
  }

  isExpired(): boolean {
    if (!this._options) {
      return false;
    }
    const expiryDate = new Date(this._options.exp * 1000);
    return expiryDate < new Date();
  }

  hasRole(role: string): boolean {
    if (this._superUser) {
      return true;
    }
    return this._roles.includes(role);
  }

  can(role: string): boolean {
    if (this._superUser) {
      return true;
    }
    return this.hasRole(role);
  }
}
