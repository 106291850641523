import GDCDevicesAPI from "./api/devices";
import {DevicesAPI} from "./domain/devices";

export interface IServices {
  devices: DevicesAPI;
}

export const Services: IServices = {
  devices: new GDCDevicesAPI(),
};
