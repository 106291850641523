import {BaseURL} from ".";
import {getOptional, put} from "../../common/fetch";
import {
  DeviceInfo,
  DevicesAPI,
  UpdateBatteryInput,
  UpdateBatteryOutput,
} from "../domain/devices";

export default class GDCDevicesAPI implements DevicesAPI {
  async getDeviceBySerialNumber(search: string): Promise<DeviceInfo | null> {
    return getOptional<DeviceInfo>(
      `${BaseURL}/device/{deviceNumber}/${encodeURIComponent(search)}`
    );
  }

  async updateBattery(
    input: UpdateBatteryInput
  ): Promise<UpdateBatteryOutput> {
    return await put(`${BaseURL}/devices/batteries`, input);
  }
}
