import React, {Component, ReactElement} from "react";

interface NoResultsProps {
  search: string;
}

export default class NoResults extends Component<NoResultsProps> {
  render(): ReactElement {
    const {search} = this.props;
    return (
      <div className="no-results">
        <h2>Sorry!</h2>
        <p>
          We can&apos;t seem to find any product that matches that serial
          number.
          <br />
          <span>{search}</span>
        </p>

        <p>
          <strong>Check if the serial number is correct and try again.</strong>
          <br />
          If you need assistance please reach out to your local whole sales
          office.
        </p>
      </div>
    );
  }
}
