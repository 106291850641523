import React, {Component, ReactElement} from "react";
import {User} from "../../service/user";
import {
  hasStoredToken,
  userFromStorage,
  loginSilent,
  handleLoginRedirect,
} from "./auth";
import Login from "./login";

export const UserContext = React.createContext<User>(new User());

interface UserContextState {
  user: User | null;
  waiting: boolean;
}

export class UserContextView extends Component<unknown, UserContextState> {
  constructor(props: unknown) {
    super(props);

    const user = userFromStorage();

    if (user !== null && user.isExpired()) {
      // try to obtain a token silently, if there is the possibility
      if (hasStoredToken()) {
        this.trySilentLogin(user);
      }

      this.state = {
        user: null,
        waiting: true,
      };
      return;
    }

    this.state = {
      user,
      waiting: true,
    };
  }

  componentDidMount() {
    // this function handles any code after a sign-in redirection
    // if there is no code, nothing bad happens: the UI renders for anonymous
    // users
    handleLoginRedirect().then(
      () => {
        // the user is logged-in
        const user = userFromStorage();

        this.setState({
          user,
          waiting: false,
        });
      },
      () => {
        this.setState({
          waiting: false,
        });
      }
    );
  }

  trySilentLogin(user: User): void {
    let hint = user.options?.preferred_username;

    if (hint === undefined) {
      const emails = user.options?.emails;
      hint = emails ? emails[0] : undefined;
    }

    if (!hint) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state = {
        user: null,
        waiting: false,
      };
      return;
    }

    loginSilent(user).then(
      (user) => {
        this.setState({
          user,
          waiting: false,
        });
      },
      () => {
        this.setState({
          user: null,
          waiting: false,
        });
      }
    );
  }

  onLogin(user: User): void {
    // this function can be used only if login popup is configured,
    // it is not used when the user is redirected to the sign-in page
    this.setState({
      user,
    });
  }

  render(): ReactElement {
    const {user, waiting} = this.state;

    if (waiting) {
      return <></>;
    }

    if (user === null) {
      return <Login onLogin={(user) => this.onLogin(user)} />;
    }

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
