import AppTitle from "./app-title";
import React, {Component, ReactElement} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {getRoutes} from "./routes";
import {User} from "../../service/user";
import {aad_logout} from "./auth";

export interface LayoutProps {
  id?: string;
  className?: string;
  user: User;
}

export interface LayoutState {
  menuOpen?: boolean;
}

export default class Layout extends Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  toggleUserMenu(): void {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  closeUserMenu(): void {
    this.setState({
      menuOpen: false,
    });
  }

  async logout(): Promise<void> {
    await aad_logout();
    window.location.reload();
  }

  render(): ReactElement {
    const {user} = this.props;
    const {menuOpen} = this.state;
    const routes = getRoutes();

    return (
      <div className="app">
        <header className={"app-header" + (menuOpen ? " open" : "")}>
          <div>
            <span tabIndex={0} onClick={() => this.toggleUserMenu()}>
              {user.name}
            </span>
          </div>
          <ul className="ug-menu pull-right">
            <li className="ug-submenu">
              <span tabIndex={0} onClick={() => this.logout()}>
                <strong>Logout</strong>
              </span>
            </li>
          </ul>
        </header>
        <div onClick={() => this.closeUserMenu()}>
          <AppTitle />
          <div id="content-area">
            <BrowserRouter>
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    index={route.path === "/"}
                    key={index}
                    path={route.path}
                    element={route.main}
                  />
                ))}
              </Routes>
            </BrowserRouter>
          </div>
        </div>
      </div>
    );
  }
}
