export const en = {
  // key1: "value of key1 in en",
  // interpolation: (what: string, how: string) => `${what} is ${how}`,
  // dateFormat: (date: Date) =>
  //   `The current date is ${formatDate(date, "MM/DD/YYYY")}`,
  // keyWithCount: (count: number) => `${count} item${count === 1 ? "" : "s"}`,

  Errors: {
    CannotCopyOrMoveFolderIntoItself: {
      title: "A folder cannot be moved or copied into itself",
      message: "The destination folder is inside the source folder.",
    },
  },

  NoReleaseConfigured: "There is no release configured.",
  CreateYourFirstRelease: "Create the first release",
  Search: "Search…",

  CreateNewRelease: "Create a new release",
  EditRelease: "Release base settings",
  Name: "Name",
  Picture: "Picture",
  TheAlbumIsEmpty: "The album is empty",

  ErrorInsertValue: "Please insert a value",

  Confirm: "Confirm",
  Cancel: "Cancel",
  Save: "Save",
  Update: "Update",

  Pages: {
    Releases: "Releases",
    HCPs: "HCPs",
    Invitations: "Invitations",
    Downloads: "Downloads",
    NewRelease: "New release",
    About: "About",
  },

  Menu: {
    Dashboard: "Releases",
    Downloads: "Downloads",
    Account: "Account",
    About: "About",
    HCPs: "HCPs",
    Invitations: "Invitations",
  },
};
