import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ErrorPanel from "../../common/error";
import formatDate from "../../../common/format-date";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {Button, InputBase} from "@material-ui/core";
import {Device, DevicePart} from "../../../service/domain/devices";
import {IServices} from "../../../service/services";

interface OverviewProps {
  data: Device;
  services: IServices;
  onUpdate: () => void;
}

interface OverviewState {
  newSerialNumber: string;
  editing: DevicePart | null;
  confirming: boolean;
  waiting: boolean;
  error: ApplicationError | null;
}

export default class SerialNumberOverview extends Component<
  OverviewProps,
  OverviewState
> {
  constructor(props: OverviewProps) {
    super(props);

    this.state = {
      newSerialNumber: "",
      editing: null,
      confirming: false,
      waiting: false,
      error: null,
    };
  }

  onEditClick(part: DevicePart): void {
    this.setState({
      newSerialNumber: "",
      editing: part,
      confirming: false,
    });
  }

  onCancelClick(): void {
    if (this.state.confirming) {
      this.setState({
        confirming: false,
        error: null,
      });

      return;
    }
    this.setState({
      editing: null,
    });
  }

  onUpdateClick(): void {
    this.setState({
      confirming: true,
      error: null,
    });
  }

  onConfirmClick(): void {
    if (this.state.waiting) {
      return;
    }

    const {data, services} = this.props;
    const {newSerialNumber} = this.state;

    this.setState({
      waiting: true,
      error: null,
    });

    // web request to update the information
    services.devices
      .updateBattery({
        batterySerialNumber: encodeURIComponent(newSerialNumber),
        hearingAidSerialNumber: data.serialNumber
      })
      .then(
        (data) => {
          if (data.status !== "success") {
            this.setState({
              waiting: false,
              error: {
                name: "Technical Error",
                status: 500,
                title: "Operation failed",
                message:
                  data.message ||
                  "The operation failed due to a technical error.",
              },
            });
          } else {
            this.setState({
              waiting: false,
              editing: null,
            });

            this.props.onUpdate();
          }
        },
        (error) => {
          this.setState({
            waiting: false,
            error,
          });
        }
      );
  }

  onRecoverClick(editing: DevicePart): void {
    this.setState({
      newSerialNumber: editing.change?.serialNumber || "",
    });
  }

  onNewSerialNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      newSerialNumber: e.target.value,
    });
  }

  render(): ReactElement {
    const {data} = this.props;
    const {editing, waiting} = this.state;
    return (
      <div className="sn-overview">
        {waiting && <Loader className="covering" />}
        <h2>Serial number overview</h2>
        <p className="note">
          Change the battery serial number by clicking Update below.
        </p>
        <table>
          <thead>
            <tr>
              <th>Product Type</th>
              <th>Serial Number</th>
              <th>Latest Update</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.parts.map((part, index) => (
              <tr key={index}>
                <td>{part.type}</td>
                <td>{part.serialNumber}</td>
                <td>{formatDate(part.timestamp)}</td>
                <td>
                  {/battery/i.test(part.type) && (
                    <span tabIndex={0} onClick={() => this.onEditClick(part)}>
                      Update
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {editing !== null && (
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="dialog serial-number-form medium"
          >
            {this.renderEditDialog()}
          </Dialog>
        )}
      </div>
    );
  }

  renderEditDialog(): ReactElement {
    const {editing, error, confirming, newSerialNumber} = this.state;

    if (editing === null) {
      return <></>;
    }

    if (confirming) {
      return (
        <>
          <DialogTitle>
            Update the battery to this new serial number:
          </DialogTitle>
          <DialogContent>
            <h3>{newSerialNumber}</h3>
            <hr />
            <p>
              This action can&apos;t be undone.
              <br />
              Are you sure you want to update this battery?
            </p>
            {error && <ErrorPanel error={error} />}
          </DialogContent>
          <DialogActions className="dialog-buttons">
            <Button
              onClick={() => this.onCancelClick()}
              autoFocus
              className="cancel-button"
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.onConfirmClick()}
              className="confirm-button"
              color="primary"
            >
              Confirm Update
            </Button>
          </DialogActions>
        </>
      );
    }

    return (
      <>
        <DialogTitle>Battery serial number</DialogTitle>
        <DialogContent>
          <div className="current-value-area">
            <dl>
              <dt>Current battery serial number</dt>
              <dd>{editing.serialNumber}</dd>
              <dt>Last update time</dt>
              <dd>{formatDate(editing.timestamp)}</dd>
            </dl>
          </div>
          <div className="edit-value-area">
            <label htmlFor="new-serial-number">New serial number</label>
            <InputBase
              id="new-serial-number"
              name="value"
              value={newSerialNumber}
              onChange={this.onNewSerialNumberChange.bind(this)}
              autoFocus
              fullWidth
            />
            {editing.change !== null && (
              <span tabIndex={0} onClick={() => this.onRecoverClick(editing)}>
                Recover the previous serial number:{" "}
                <strong>{editing.change.serialNumber}</strong>
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <Button
            onClick={() => this.onCancelClick()}
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.onUpdateClick()}
            className="confirm-button"
            disabled={
              !newSerialNumber || newSerialNumber === editing.serialNumber
            }
            color="primary"
          >
            Next
          </Button>
        </DialogActions>
      </>
    );
  }
}
