import React, {ReactElement} from "react";
import {redirectToLoginPage} from "./auth";
import {User} from "../../service/user";
import AppTitle from "./app-title";

export interface LoginProps {
  onLogin: (user: User) => void;
}

export interface LoginState {
  showPasswordReset: boolean;
  loading: boolean;
}

export default class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      showPasswordReset: false,
      loading: false,
    };
  }

  onLoginClick(): void {
    redirectToLoginPage();
  }

  render(): ReactElement {
    return (
      <div className="login-screen">
        <AppTitle />
        <div className="login-area">
          <button className="login-button" onClick={() => this.onLoginClick()}>
            Login
          </button>
        </div>
      </div>
    );
  }
}
