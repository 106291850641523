import JwtDecode from "jwt-decode";
import {User} from "../../../service/user";

export interface JwtToken {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  preferred_username: string;
  roles: string[];
}

export function isExpired(token: JwtToken): boolean {
  const expiryDate = new Date(token.exp * 1000);
  return expiryDate < new Date();
}

export function userFromAccessToken(token: string | null): User | null {
  if (!token) {
    return null;
  }

  try {
    const jwt = JwtDecode<JwtToken>(token);
    return new User(jwt);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return null;
  }
}
