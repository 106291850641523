import React, {Component, ReactElement} from "react";
import {User} from "../../service/user";
import {UserContext, UserContextView} from "./user-context";

// examples:
//   locale: vocabulary
//   countries: countries
//   services: services
export interface IAppContext {
  user: User;
}

export const AppContext = React.createContext<IAppContext>({
  user: new User(),
});

export class AppContextView extends Component<unknown, unknown> {
  render(): ReactElement {
    return (
      <UserContextView>
        <UserContext.Consumer>
          {(user) => (
            <AppContext.Provider value={{user}}>
              {this.props.children}
            </AppContext.Provider>
          )}
        </UserContext.Consumer>
      </UserContextView>
    );
  }
}
