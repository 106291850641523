import Head from "../common/head";
import React from "react";
import Search from "./serial-numbers/search";
import {Services} from "../../service/services";

export default function Home(): React.ReactElement {
  return (
    <div className="wrapper">
      <Head title="Portal" />
      <section>
        <Search services={Services} />
      </section>
    </div>
  );
}
