import {aad_loginRequest, aad_msalConfig} from "./config";
import {ACCESS_TOKEN_KEY} from "../../../common/fetch";
import {
  AuthenticationResult,
  CommonAuthorizationUrlRequest,
} from "@azure/msal-common";
import {User} from "../../../service/user";
import {userFromAccessToken} from "./tokens";
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

const aad_app = new PublicClientApplication(aad_msalConfig);

export type SsoSilentRequest = Partial<
  Omit<
    CommonAuthorizationUrlRequest,
    "responseMode" | "codeChallenge" | "codeChallengeMethod"
  >
>;

const storage = sessionStorage;
const TOKEN_STORAGE_KEY = "ID_TOKEN";

export function clearSession(): void {
  storage.removeItem(TOKEN_STORAGE_KEY);

  const toRemove: string[] = [];

  for (const x in storage) {
    if (
      x.indexOf("b2c_1a_kisei_signin") > -1 ||
      x.indexOf("login.windows.net") > -1
    ) {
      toRemove.push(x);
    }
  }

  for (const item of toRemove) {
    storage.removeItem(item);
  }
}

export function hasStoredToken(): boolean {
  return TOKEN_STORAGE_KEY in storage;
}

export function aad_login(): Promise<User> {
  // This function works well in modern browsers, but does not work in IE 11.
  // Since the current system supports IE 11, redirectToLoginPage is used
  // instead.
  return new Promise((resolve, reject) => {
    aad_app
      .loginPopup(aad_loginRequest)
      .then((resp) => {
        if (resp !== null) {
          storeTokens(resp);

          const user = userFromAccessToken(resp.idToken);

          if (user) {
            resolve(user);
          } else {
            reject("The JWT could not be used");
          }
        } else {
          reject("Response is null");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function aad_logout(): Promise<void> {
  return new Promise((resolve, reject) => {
    aad_app
      .logoutPopup()
      .then(() => {
        clearSession();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function userFromStorage(): User | null {
  const stored = storage.getItem(TOKEN_STORAGE_KEY);

  if (!stored) {
    return null;
  }

  return userFromAccessToken(stored);
}

function storeTokens(result: AuthenticationResult): void {
  storage.setItem(TOKEN_STORAGE_KEY, result.idToken);
  storage.setItem(ACCESS_TOKEN_KEY, result.accessToken);
}

function _loginSilent(
  app: PublicClientApplication,
  loginHint: string
): Promise<User | null> {
  // Tries to obtain a new access token silently, for the user with the given
  // login hint (email address).
  // If it fails for most reasons, it return null (the user will be requested
  // to repeat login).
  return new Promise((resolve, reject) => {
    app
      .ssoSilent({
        loginHint,
        scopes: aad_loginRequest.scopes,
      })
      .then((resp) => {
        if (resp !== null) {
          storeTokens(resp);

          const user = userFromAccessToken(resp.idToken);

          if (user) {
            resolve(user);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // perfectly fine
          resolve(null);
        } else {
          reject(error);
        }
      });
  });
}

export function loginSilent(user: User): Promise<User | null> {
  return _loginSilent(aad_app, user.email);
}

export function redirectToLoginPage(): void {
  aad_app.loginRedirect(aad_loginRequest);
}

export function handleLoginRedirect(): Promise<void> {
  return new Promise((resolve, reject) => {
    aad_app
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse === null) {
          return reject();
        }

        storeTokens(tokenResponse);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
