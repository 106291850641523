import {ReactElement} from "react";
import Home from "./home";

interface Route {
  path: string;
  main: ReactElement;
}

export function getRoutes(): Route[] {
  return [
    {
      path: "/",
      main: Home(),
    },
  ];
}
