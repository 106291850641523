import {createTheme, Theme} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

export default function getTheme(): Theme {
  return createTheme({
    palette: {
      primary: {
        main: "#000", // c6168d
      } /*
      secondary: {
        main: "#fff",
      },*/,
      error: {
        main: red.A400,
      },
      background: {
        default: "#fff",
      },
    },
    props: {
      MuiButton: {
        variant: "outlined",
        color: "primary",
        style: {
          borderWidth: 2,
          borderRadius: 0,
        },
      },
      MuiTextField: {},
    },
  });
}
